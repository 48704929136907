.body {
  .games-sections {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;

    .title {
      width: 100%;
      background-image: url("../../../assets/imgs/2551066.jpg");
      border-radius: 18px 18px 0 0;
      padding: 12px 20px;

      h2 {
        content: "Active games";
        display: block;
        background: linear-gradient(180deg, #FFFFFF 30.82%, #FFB672 86.99%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        filter: drop-shadow(0px 2px 0px #753802);
        font-family: "Lilita One", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 26px;
        line-height: normal;
      }
    }
  }
}