@import './variables';

@mixin color-game-font {
  background: linear-gradient(180deg, #FFFFFF 30.82%, #FFB672 86.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  filter: drop-shadow(0px 2px 0px #753802);
}

@mixin color-game-font-dark {
  background: linear-gradient(180deg, #FFFFFF 30.82%, #ceb8cc 86.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  filter: drop-shadow(0px 2px 0px #af129e);
}

@mixin game-font {
  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin button-ui {
  background: linear-gradient(181.21deg, rgba(255, 255, 255, 0.4) 1.04%, rgba(255, 255, 255, 0) 57%), linear-gradient(180deg, #D38433 0%, $p1 100%);
  box-shadow: inset 0px 0.59751px 0.13444px rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 3px 0px #753802);
}

@mixin bg-ui-gradient {
  background: linear-gradient(174deg, rgba(255, 255, 255, 0.4) 1.04%, rgba(255, 255, 255, 0) 57%),
    linear-gradient(180deg, #441989 0%, #e28680 100%);
  box-shadow: inset 0px 0.59751px 0.13444px rgba(255, 255, 255, 0.5);
  filter: none;
  fill: linear-gradient(174deg, rgba(255, 255, 255, 0.4) 1.04%, rgba(255, 255, 255, 0) 57%),
  linear-gradient(180deg, #441989 0%, #e28680 100%);
}

@mixin round-button-ui {
  @include bg-ui-gradient;
  border-radius: 100%;
}

@mixin button-ui-dark {
  background: linear-gradient(174deg, rgba(255, 255, 255, 0.4) 1.04%, rgba(255, 255, 255, 0) 57%),
    linear-gradient(180deg, #441989 0%, #e28680 100%);
  box-shadow: inset 0px 0.59751px 0.13444px rgba(255, 255, 255, 0.5);
  filter: none;
}

@mixin width-content {
  width: 80vw;
  min-width: 360px;
}

@mixin col-1 {
  min-width: 80px;
}

@mixin col-2 {
  min-width: 200px;
}

@mixin col-3 {
  min-width: 110px;
}

@mixin col-4 {
  min-width: 130px;
}

@mixin col-5 {
  min-width: 130px;
}

@mixin col-6 {
  min-width: 170px;
}

@mixin col-7 {
  max-width: 150px;
  width: 100%;
}