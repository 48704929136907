// Colors

$p1: #B5702B;
$p2: #864E15;
$p3: #714110;
$p4: #ffcd9f;

$p1-dark: #3d444d;
$p2-dark: #ffffff;
$p3-dark: #e768ff;

$goldFradient: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);

$mobile: 600px;