.game-field {
  // transform: skewX(0deg) scaleY(0.5); /* Initial skew and compressed state */
  // animation: boardAnimation .5s ease-out forwards;
}

@keyframes boardAnimation {
  0% {
    transform: skewX(0deg) scaleY(0.5); /* Starting point: skewed and horizontally compressed */
  }
  100% {
    transform: skewX(0deg) scaleY(1); /* Ending point: regular square */
  }
}
.game-preview-container {
  width: 100%;
  cursor: pointer;
  position: relative;
  // transform: scale(0.8);

  &-cover {
    position: absolute;
    // background: red;
    left: 0;
    top: 0;
    bottom: 56px;
    right: 0;
    z-index: 1;
    cursor: pointer;
    background: #c9843d;
    opacity: 0.6;
  }
}