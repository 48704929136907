@import "../../App/mixins";
@import "../../App/variables.scss";

body,
p, p .address-value {
  color: $p2;
  font-weight: 500 !important;
}

.header {
  padding: 10px 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  .logo-dark {
    display: none;
  }
  .right-side {
    display: flex;
    gap: 10px;
    align-items: center;
    .btn-profile {
      padding: 0 8px;
      border-radius: 10px;
      border-left: 1px solid #fdcc9e;
      span {text-transform: lowercase;}
      &:hover {
        transform: scale(1.025);
      }
    }
    .address-value {
      border: 4px solid $p4;
      border-radius: 100px;
    }
  }
  // .logo {}

  // .wallet-connect {}
}

.how-to-play {
  padding: 0px 20px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: auto;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;

  .button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgb(20, 20, 20);
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
    position: relative;
  }

  .icon {
    width: 12px;
    transition-duration: 0.3s;
  }

  .button {
    &:hover {
      width: 140px;
      border-radius: 50px;
      transition-duration: 0.3s;
      background-color: rgb(181, 160, 255);
      align-items: center;

      .icon {
        /* width: 20px; */
        transition-duration: 0.3s;
        transform: translateY(-200%);
      }
    }

    &::before {
      position: absolute;
      bottom: -20px;
      content: "How to play?";
      @include game-font;
      @include color-game-font;

      /* transition-duration: .3s; */
      font-size: 0px;
    }

    &:hover::before {
      font-size: 19px;
      opacity: 1;
      bottom: unset;

      /* transform: translateY(-30px); */
      transition-duration: 0.3s;
    }
  }
}
.theme {
  padding: 0px 20px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999; //test mode

  .button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgb(20, 20, 20);
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
    position: relative;
    padding: 0 9px;

    &:hover {
      width: 100px;
      border-radius: 50px;
      transition-duration: 0.3s;
      background-color: rgb(181, 160, 255);
      align-items: center;

      .icon {
        transition-duration: 0.3s;
      }

      .toggle-switch {
        display: inline-block;
      }
    }

    .icon {
      transition-duration: 0.3s;
      font-size: 18px;
    }

    .toggle-switch {
      width: 40px;
      height: 20px;
      background-color: #f9c497;
      border-radius: 20px;
      position: relative;
      margin-left: 10px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      display: none;

      &.toggled {
        background-color: #4caf50;
        .toggle-knob {
          left: 21px;
        }
      }

      .toggle-knob {
        width: 18px;
        height: 18px;
        background-color: white;
        border-radius: 50%;
        position: absolute;
        top: 1px;
        left: 1px;
        transition: left 0.3s ease;
      }
    }
  }
}

.container {
  padding: 20px;
}
.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.5) !important;

}

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 0;

  .body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @include width-content;
    margin: 0 auto;

    .actions {
      display: flex;
      margin-bottom: 15px;
      justify-content: space-between;
      align-items: baseline;
      width: 100%;
      &.profile {
        flex-direction: column;
        .group-btns {
          position: absolute;
        }
      }
      p {
        @include game-font;
        font-size: 25px;
        @include color-game-font;
      }
    }
    .tabs {
      width: 100%;
      background-image: url("../../assets/imgs/2551066.jpg");
      border-radius: 18px 18px 0 0;
      padding: 12px 10px 0 10px;
      button {
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        padding-bottom: 15px;
        padding-left: 40px;
        padding-right: 40px;
        span {
          font-size: 26px;
          @media (max-width: $mobile) {
            font-size: 18px;
          }
        }
      }
      .MuiTabs-indicator {
        background-color: $p3;
        height: 5px;
      }
    }
  }
  .games {
    &-list {
      display: grid;
      width: 100%;
      // height: calc(100vh - 250px);
      overflow: scroll;
      border-radius: 0 0 18px 18px;
      .list-cell {
      }
      .list-header {
        display: flex;
        background-color: rgba(255, 255, 255, 0.5);
        // background-image: url('../../assets/imgs/wood-bg.jpg');
        position: sticky;
        top: 0;
        z-index: 1;
        justify-content: space-between;
        padding: 8px 10px;
        font-size: 16px;
        font-weight: 600;
        border-bottom: 1px solid $p1;
        backdrop-filter: blur(2px);
        & > div:nth-child(1) {
          @include col-1;
        }
        & > div:nth-child(2) {
          @include col-2;
        }
        & > div:nth-child(3) {
          @include col-3;
        }
        & > div:nth-child(4) {
          @include col-4;
        }
        & > div:nth-child(5) {
          @include col-5;
        }
        & > div:nth-child(6) {
          @include col-6;
        }
        & > div:nth-child(7) {
          @include col-7;
        }
      }
      .list-row {
        background-color: rgba(255, 255, 255, 0.5) !important;
        box-shadow: none;
        margin: 0 !important;
        border-bottom: 1px solid $p1;
        border-radius: 0;
        padding: 0px !important;
        transition: all 0.3s;
        &:last-child {
          border: 0;
        }
        img {
          width: 20px;
          &.link-icon {
            width: 11px;
          }
        }
        .list-cell {
          &:nth-child(1) {
            @include col-1;
          }
          &:nth-child(2) {
            @include col-2;
          }
          &:nth-child(3) {
            @include col-3;
            align-items: center;
            display: flex;
            gap: 5px;
          }
          &:nth-child(4) {
            @include col-4;
          }
          &:nth-child(5) {
            @include col-5;
          }
          &:nth-child(6) {
            @include col-6;
          }
          &:nth-child(7) {
            @include col-7;
          }
        }

        .col-time {
          align-items: center;
          display: flex;
          gap: 5px;
        }
        .col-gameSize {
          align-items: center;
          display: flex;
          gap: 5px;
        }
        .col-winner {
          @include col-5;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .col-winner-total {
            align-items: center;
            display: flex;
            gap: 5px;
            color: inherit;
          }
        }
        .col-action {
          padding: 0;
          display: flex;
        }
        .list-row-body {
          display: flex;
          padding: 0;
          align-items: center;
          justify-content: space-between;
          padding: 10px !important;
          [class^="col-"] {
            p {
              align-items: center;
              display: flex;
              gap: 5px;
            }
          }
        }
      }
    }
  }
}

button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  width: max-content;
  border-radius: 10px;
  @include button-ui;
  > span {
    @include game-font;
    text-transform: capitalize;
    font-size: 19px;
    @include color-game-font;
    // text-fill-color: transparent;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

.MuiCardContent-root:hover {
  background-color: rgba(255, 255, 255, 0.35);
  transition: all 0.3s;
}

.game-content {
  display: flex;
  flex-direction: column;
  .actions {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      gap: 10px;
      // transition: all 0.3s;
      .btn-back {
        min-width: 45px;
        height: 45px;
        padding: 6px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(20, 20, 20);
        border: none;
        font-weight: 600;
        cursor: pointer;
        position: relative;
        transition: all 0.3s ease;
        overflow: hidden;
        text-transform: capitalize;

        &::before {
          position: absolute;
          content: "⬅︎ Back";
          font-size: 0px;
          color: white;
          transition: all 0.3s ease;
          opacity: 0;
          @include game-font;
          @include color-game-font;
        }

        &:hover {
          min-width: 100px;
          justify-content: center;
          transition: all 0.3s ease;
          span {
            display: none;
          }

          &::before {
            font-size: 19px;
            opacity: 1;
          }
        }

        &:not(:hover) {
          transition: all 0.3s ease;
          min-width: 45px;

          &::before {
            font-size: 0px;
            opacity: 0;
          }
        }
      }
    }
    p {
      @include game-font;
      font-size: 25px;
      @include color-game-font;
    }
  }
  .player-trophy {
    position: absolute;
    top: 45px;
    z-index: 1;
    &-p1 {
      right: unset;
      left: 15px;
    }
    &-p2 {
      left: unset;
      right: 15px;
    }
  }
  .game-field {
    position: relative;
    padding: 30px;
    filter: drop-shadow(5px 5px 0px #753802);
    background-image: url("../../assets/imgs/2551066.jpg");
    border-radius: 18px;
    display: inline-block;
    .players {
      display: flex;
      justify-content: center;
      gap: 30px;
      @include game-font;
      font-size: 18px;
      @include color-game-font;
      position: relative;
      .logo {
        width: 50px;
        height: 50px;
      }
      .player {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        line-height: 1.6;
        &.active-turn {
          .chip {
            opacity: 1;
            animation: borderPulses 2000ms infinite ease-out;
            @keyframes borderPulses {
              0% {
                box-shadow: inset 0px 0px 0px -5px rgba(255, 255, 255, 0.1),
                  0px 0px 0px 0px rgb(255 212 170);
                // box-shadow: inset 0px 0px 0px -5px rgba(255, 255, 255, 0.1),
                //   0px 0px 0px 0px rgb(202 55 207);
              }

              100% {
                box-shadow: inset 0px 0px 0px 0px rgba(117, 117, 255, 0.03),
                  0px 0px 0px 15px rgb(255 255 255 / 0%);
              }
            }
          }
          .progress-container {
            opacity: 1;
          }
        }
        .progress-container {
          opacity: 0.5;
          svg {
            color: #e1a46a;
          }
        }
        .chip {
          position: absolute;
          background: radial-gradient(circle at 30% 30%, #5f5f5f, #000);
          box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
          height: 25px;
          width: 25px;
          right: 20px;
          opacity: 0.75;
          border-radius: 20px;
        }
        &:first-child {
          flex-direction: row-reverse;
          .chip {
            left: 20px;
            background: radial-gradient(circle at 30% 30%, white, #868484);
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
          }
          .player-info {
            flex-direction: row;
            .copied-icon {
              left: 75px;
              position: absolute;
            }
          }
          .time {
            align-items: flex-end !important;
            .row {
              flex-direction: row-reverse;
            }
          }
        }
        .player-col {
          .player-info {
            display: flex;
            align-items: center;
            gap: 5px;
            .rating {
              font-weight: 100;
              font-size: 15px;
              font-family: sans-serif;
            }
            .address-container {
              // position: relative;
              > div {
                display: none;
              }
              .address-value {
                cursor: pointer;
                color: white;
              }
              .copied-icon {
                transition: all 0.3s ease;
                position: absolute;
                right: 75px;
              }
            }
          }
          .time {
            align-items: flex-start;
            flex-direction: column;
            display: flex;
            .row {
              display: flex;
              gap: 5px;
              align-items: center;
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.input-wrapper-search {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
}

.input-search {
  border-style: none;
  height: 45px;
  width: 45px;
  padding: 10px;
  outline: none;
  border-radius: 100px;
  transition: 0.5s ease-in-out;
  background-color: #b5702bba;
  box-shadow: 0px 0px 3px #f3f3f3;
  padding-right: 35px;
  color: #864e15;
}

.input-search::placeholder,
.input-search {
  font-family: "Lilita One", sans-serif;
  font-size: 17px;
}

.input-search::placeholder {
  color: #864d1589;
}

.icon-search {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  cursor: pointer;
  width: 45px;
  height: 45px;
  outline: none;
  border-style: none;
  border-radius: 100px;
  pointer-events: painted;
  background-color: transparent;
  transition: 0.2s linear;
}
.icon-search:hover {
  transform: scale(1.0);
}

button.icon-search:focus, button.icon-search.active {
  background: transparent;
  box-shadow: none;
}

.icon-search:focus ~ .input-search,
.input-search:focus {
  box-shadow: none;
  width: 250px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 3px solid #b5702b;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.group-btns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.small-counter {
  font-size: 14px !important;
  margin-left: 6px;
}

.MuiTab-wrapper {
  flex-direction: row !important;
}
