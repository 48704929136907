@import "../../App/mixins";
@import "../../App/variables.scss";

@mixin verticalCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  &.dark {
    .profile {
      &-card {
        &-user-info {
          display: flex;
          gap: 20px;
          flex-direction: row;
          margin-bottom: 20px;

          .table-row.table-header {
            gap: 30px;
            background-color: #2a333d !important;
            background-image: none;
            border: 0;
            border-radius: 18px 18px 0 0;
            padding: 12px 20px;

            .table-cell p {
              font-size: 15px;
            }
          }

          .table-row {
            background-color: rgba(255, 255, 255, 0.5);

            .table-cell {
              justify-content: center;
            }
          }
        }
      }
      .details {
        .section-details-avatar {
          border: 4px solid $p3-dark;
        }
        .section-details-info {
          &-nickname {
            @include color-game-font-dark;
            filter: none;
            display: flex;
            gap: 8px;
            align-items: center;
          }
          &-address {
            @include color-game-font-dark;
            filter: none;
          }
        }
      }
      .games-list {
        .content {
          .title {
            background-color: #2a333d;
            background-image: none;

            h2 {
              @include color-game-font-dark;
            }
          }
        }
      }
    }
  }
}

.profile {
  &-card {
    &-user-info {
      display: flex;
      gap: 20px;
      flex-direction: row;
      margin-bottom: 20px;
      @media (max-width: $mobile) {
        flex-direction: column;
      }

      .table-row.table-header {
        gap: 30px;
        background-image: url("../../assets/imgs/2551066.jpg");
        border-radius: 18px 18px 0 0;
        padding: 12px 20px;

        .table-cell p {
          font-size: 15px;
          @media (max-width: $mobile) {
            font-size: 0;
          }
        }
      }
      .table-row {
        background-color: rgba(255, 255, 255, 0.5);

        .table-cell {
          justify-content: center;
        }
      }
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    &-header-container {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 10px;
      justify-content: right;

      @media (max-width: $mobile) {
        gap: 5px;
        flex-direction: row-reverse;
      }
    }
    .section-details-avatar {
      border-radius: 100px;
      border: 4px solid $p4;
      @media (max-width: $mobile) {
        display: none;
      }
    }
    .section-details-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      @media (max-width: $mobile) {
        gap: 20px;
      }
      &-nickname {
        @include color-game-font;
        @include game-font;
        font-size: 28px;
        line-height: 1.2;
      }
      &-address {
        @include color-game-font;
        @include game-font;
        font-size: 18px;
      }
    }
  }
  .games-list {
    gap: 20px;
    .content {
      .title {
        width: 100%;
        background-image: url("../../assets/imgs/2551066.jpg");
        border-radius: 18px 18px 0 0;
        padding: 12px 20px;
        display: flex;
        h2 {
          @include color-game-font;
          @include game-font;
          font-size: 26px;
          line-height: normal;
        }
      }
    }

    .games-current {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Three equal columns */
      gap: 10px 30px;
      margin-top: 20px;
    }
  }
  .weekly-rating {
    max-width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 130px;
    gap: 10px;
    background: linear-gradient(43deg, #d4b068 0%, #f2d799 40%, #ffebb9 100%);
    padding: 8px;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    transform-style: preserve-3d;
    perspective: 1000px;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    cursor: pointer;

    @media (max-width: $mobile) {
      margin: auto;
    }

    &:hover {
      transform: rotateY(10deg) rotateX(10deg) scale(1.05);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
      transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
      z-index: 1;
    }

    &::before {
      left: 0;
    }
    &::after {
      right: 0;
      left: auto;
    }

    &:hover::before {
      transform: translateX(-100%);
    }
    &:hover::after {
      transform: translateX(100%);
    }

    &-title {
      font-size: 13px;
      line-height: normal;
      font-weight: bold;
    }

    &-data {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;

      &-points {
        font-size: 24px;
        line-height: normal;
        font-weight: bold;
      }
    }
  }
}
