@import '../../../../App/mixins';

.dialog-modal { 
  // span {color: #000;}
  [aria-labelledby] {
    border-radius: 18px;
    div[id$="-dialog-title"] {
      background-image: url('../../../../assets/imgs/2551066.jpg');
      h2 {
        @include game-font;
        font-size: 22px;
        @include color-game-font;
      }
    }
    >div {
      &:last-child, &.actions {
        display: flex;
        justify-content: space-between;
        padding: 8px 24px 24px;
      }
    }
    .game-size-formrow {
      [aria-label="play-with"] {
        display: flex !important;
        flex-direction: row;
        gap: 20px;

        label {
          width: auto;
        }
      }
    }
    fieldset {
      legend {
        @include game-font;
        font-size: 22px;
        color: #c78139;
        margin-bottom: 8px;
      }
      [aria-label] {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 8px;
        label {
          display: flex;
          align-items: flex-start;
          margin-left: 0;
          margin-right: 16px;
          width: 160px;
          flex-direction: column;
          >span {
            padding: 0 0 9px 0;
            line-height: normal;
            // [class$="-checked"] {color: #c78139 !important}
            div {
              display: flex;
              flex-direction: column;
            }
            .radio-description {
              font-size: 13px;
              line-height: normal;
              opacity: 0.7;
              display: none !important;
            }
          }
        }
      }
      .opponent-address, .set-bet {
        label {
          color: #864E15;
          @include game-font;
          opacity: .7;
        }
        >div {
          input {
            color: #864E15;
            @include game-font;
          }
          &:before {
            border-bottom: 1px solid #e5cdb5;
          }
          &:after {
            border-bottom: 2px solid #864E15
          }
        }
      }
    }
  }
  &.game-options {
    padding: 8px 16px;
    h2 {
      text-align: center;
      font-size: 28px !important;
      line-height: 1.2;
    }
    .game-time-svg {
      @media (max-width: $mobile) {
        // width: 70px;
        // height: 50px;
      }
    }
    .game-options-body {
      >.game-type-formrow {
        &:nth-child(1) {
          [aria-label] {
            gap: 40px;
            svg {
              filter: hue-rotate(45deg);
              @media (max-width: $mobile) {
                // width: 70px;
                // height: 50px;
              }
            }
          }
        }
        &:nth-child(2) {
          [aria-label] {
            gap: 47px;
            svg {
              filter: hue-rotate(110deg);
            }
          }
        }
      }
      legend {
        background-color: #fff;
        text-shadow: 0px 1px 0px #753805;
        padding: 0px 5px 0px 5px;
        top: -40px;
        font-size: 24px;
        position: absolute;
        margin: 0;
      }
      .game-type-formrow {
        border: 1px solid #e5cdb5;
        border-radius: 5px;
        padding: 25px 15px 15px;
        width: 100%;
        &.nickname {
          width: 100% !important;
          fieldset {
            display: flex;
            align-items: center;
            .set-bet {
              display: flex;
              align-items: center;
              label {
                text-align: center;
                width: 100%;
                transform-origin: top center;
              }
              input {
                text-align: center;
                font-size: 28px;
              }
            }
          }
        }
        fieldset {
          [aria-label] {
            flex-wrap: nowrap;
            gap: 5px;
            label {
              margin: 0;
              width: auto;
              flex-direction: row;
              align-items: center;
              gap: 4px;
              padding: 10px;
              svg {opacity: .5;}
              .info {display: none;}
              &:hover {
                background: linear-gradient(180deg, rgb(255 244 232) 0%, rgb(255 246 238) 100%);
                border-radius: 7px;
                box-shadow: inset 0px 0.59751px 0.13444px rgba(255, 255, 255, 0.5);
              }
              &.checked {
                background: linear-gradient(180deg, rgb(255 230 203) 0%, rgb(237 179 121 / 36%) 100%);
                border-radius: 7px;
                box-shadow: inset 0px 0.59751px 0.13444px rgba(255, 255, 255, 0.8);
                svg {
                  filter: hue-rotate(160deg);
                  opacity: 1;
                }
                .info {
                  position: absolute;
                  right: -18px;
                  top: -18px;
                  display: flex;
                  background: transparent;
                  box-shadow: none;
                  filter: none;

                  span {
                    filter: none;
                    color: #e4974c;
                    svg {
                      filter: none
                    }
                  }
                }
              }
              > span {
                @include game-font;
                font-size: 18px;
                padding: 0;
                color: #864E15;
                .radio-description {
                  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: $mobile) {
    .MuiDialogContent-root {
      padding: 8px 10px;
    }
    .game-options {
      &-body {
        .game-type-formrow {
          padding: 15px 0px 15px !important;

          fieldset {
            padding: 0 5px;
            .MuiFormGroup-root {
              gap: 10px !important;
              height: 40px;

              label.checked svg {
                width: 20px;
              }
            }
          }
        }
        .columns-row {
          flex-direction: column;
        }
      }
    }
    .MuiDialogActions-root.actions {
      button {
        flex: 1
      }
    }
  }
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #c78139 !important;
}
.MuiIconButton-root:hover, .MuiIconButton-colorSecondary:hover {background-color: transparent;}
