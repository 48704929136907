@import "./App/mixins";
@import './App/variables.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:root {
  --color-green: #3a953a;
  --color-red: #f16d6d;
  --color-white: #fff;
  --color-black: #000;
}

html {
  font-size: 62.5%; /* our desired size: 10px / default browser font size: 16px => 10px/16px = 62.5%  => 1rem = 10px */
}

body {
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  -webkit-appearance: none;
}

#root {
  min-height: 100vh;
}

.iekbcc0.ju367v75.ju367v7q.ju367v8a.ju367v6j.ju367va9.ju367vcl.ju367vn.ju367vt.ju367vw.ju367vfu.ju367v16.ju367v1h.ju367v8u {
  border-left: 1px #fff solid;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.iekbcc0.ju367v8, .iekbcc0.ju367v72.ju367v7n.ju367v4.ju367va.ju367v2a {
  font-size: 19px;
}

.iekbcc0.ju367v75.ju367v7q.ju367v8a.ju367v6j.ju367va3.ju367vcl.ju367vn.ju367vt.ju367vw.ju367vfu.ju367v16.ju367v1h.ju367v8u {
  border-top: transparent;
  border-right: transparent;
  border-bottom: transparent;
  border-color: #fdcc9e;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 1px;
}

.dark .iekbcc0.ju367v75.ju367v7q.ju367v8a.ju367v6j.ju367va3.ju367vcl.ju367vn.ju367vt.ju367vw.ju367vfu.ju367v16.ju367v1h.ju367v8u {
  background: transparent;
  border-left: 1px solid #3d444d;
}

button.iekbcc0.iekbcc9.ju367v78.ju367v7t.ju367v9i.ju367vn.ju367vei.ju367vf3.ju367v16.ju367v1h.ju367v2g.ju367v8u._12cbo8i3.ju367v8r._12cbo8i4._12cbo8i6 {
flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  background: linear-gradient(181.21deg, rgba(255, 255, 255, 0.4) 1.04%, rgba(255, 255, 255, 0) 57%), linear-gradient(180deg, #D38433 0%, #B5702B 100%);
  border-radius: 10px;
  box-shadow: inset 0px 0.59751px 0.13444px rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 3px 0px #753802);
  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: capitalize;
  font-size: 19px;
}

.dark button.iekbcc0.iekbcc9.ju367v78.ju367v7t.ju367v9i.ju367vn.ju367vei.ju367vf3.ju367v16.ju367v1h.ju367v2g.ju367v8u._12cbo8i3.ju367v8r._12cbo8i4._12cbo8i6 {
  background: linear-gradient(174deg, rgba(255, 255, 255, 0.4) 1.04%, rgba(255, 255, 255, 0) 57%),
      linear-gradient(180deg, #441989 0%, #e28680 100%);
  box-shadow: inset 0px 0.59751px 0.13444px rgba(255, 255, 255, 0.5);
  filter: none;
  border-radius: 100px;
}

button.iekbcc0.iekbcc9.ju367v4.ju367va3.ju367vn.ju367vei.ju367vfu.ju367va.ju367v16.ju367v1h.ju367v8u._12cbo8i3.ju367v8r._12cbo8i4._12cbo8i6 {
  filter: drop-shadow(0px 3px 0px #753802);
  box-shadow: inset 0px 0.59751px 0.13444px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  height: 40px;
}

.dark button.iekbcc0.iekbcc9.ju367v4.ju367va3.ju367vn.ju367vei.ju367vfu.ju367va.ju367v16.ju367v1h.ju367v8u._12cbo8i3.ju367v8r._12cbo8i4._12cbo8i6 {
  filter: none;
  background: #2a333d;
  border: 1px solid #3d444d;
  border-radius: 100px;
}

button.iekbcc0.iekbcc9.ju367v76.ju367v7r.ju367v8b.ju367v6k.ju367v4.ju367va6.ju367vn.ju367vei.ju367vfx.ju367vb.ju367va.ju367v16.ju367v1h.ju367v1p.ju367v8u._12cbo8i3.ju367v8r._12cbo8i4._12cbo8i6 {
  filter: drop-shadow(0px 3px 0px #5b092f);
  box-shadow: inset 0px 0.59751px 0.13444px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  height: 40px;
}

.dark button.iekbcc0.iekbcc9.ju367v76.ju367v7r.ju367v8b.ju367v6k.ju367v4.ju367va6.ju367vn.ju367vei.ju367vfx.ju367vb.ju367va.ju367v16.ju367v1h.ju367v1p.ju367v8u._12cbo8i3.ju367v8r._12cbo8i4._12cbo8i6 {
  filter: none;
    /* background: #2a333d; */
    border: 1px solid #3d444d;
    border-radius: 100px;
}

.dark .iekbcc0.ju367v8f.ju367v6o.ju367v79.ju367v7u.ju367vbr {
  background-color: #2a333d;
}

.iekbcc0.ju367v8b.ju367v6k.ju367v77.ju367v7q.ju367v8 {
  color: #edcdad;
}

.dark .iekbcc0.ju367v8b.ju367v6k.ju367v77.ju367v7q.ju367v8 {
  color: #8397b2;
}

.Toastify__close-button {
  background: transparent !important;
}

button.Toastify__close-button.Toastify__close-button--dark {
  filter: none;
  background: transparent;
}
.Toastify__toast {
  background: #90581b;

  div[role="alert"] {
    background: #90581b;
    padding: 2px 7px;
    
    a {
      color: #ffb58c;
    }
  }
}
.dark .Toastify__toast {
  background: #525d69;

  div[role="alert"] {
    background: #525d69;
    padding: 2px 7px;
    
    a {
      color: #6e3e77;
    }
  }
}

.dark button.Toastify__close-button.Toastify__close-button--classic {
  background: transparent;
}

.Toastify__toast-body {
  font-size: 14px;
  font-family: "Lilita One", sans-serif;
}

.iekbcc0._1ckjpok4._1ckjpok1.ju367vb6.ju367vdr.ju367vp.ju367vt.ju367vv.ju367vel.ju367va.ju367v15.ju367v6c.ju367v8r button,
.iekbcc0.ju367v86.ju367v6o.ju367v70.ju367v7l.ju367va.ju367v15.ju367v1n._18dqw9x0 button {
  box-shadow: none;
}

.dark .iekbcc0._1ckjpok4._1ckjpok1.ju367vb6.ju367vdr.ju367vp.ju367vt.ju367vv.ju367vel.ju367va.ju367v15.ju367v6c.ju367v8r,
.dark .iekbcc0._1ckjpok1.ju367vb6.ju367vdr.ju367vp.ju367vt.ju367vv.ju367vel.ju367va.ju367v15.ju367v6c.ju367v8r {
  background-color: #2a333d;
}

.dark .iekbcc0._1ckjpok4._1ckjpok1.ju367vb6.ju367vdr.ju367vp.ju367vt.ju367vv.ju367vel.ju367va.ju367v15.ju367v6c.ju367v8r button,
.dark .iekbcc0.ju367v86.ju367v6o.ju367v70.ju367v7l.ju367va.ju367v15.ju367v1n._18dqw9x0 button {
  box-shadow: none;
  border-radius: 100px !important;
}

.iekbcc0.ju367va.ju367v15>button {
width: 100%;
border-radius: 100px;
}

.dark .iekbcc0.ju367va.ju367v15>button {
  width: 100%;
}

.dark .iekbcc0.ju367va.ju367v15>button>div:hover {
  border-radius: 100px;
}

.rotate-45 {
  transform: rotate(45deg);
}

.tiny-counter{
  @include color-game-font;
  font-size: 13px;
  margin-left: 8px;
}


  .player-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    line-height: 1.6;
    &.small {
      transform: scale(0.7) translate(-18%, 0px);
    }
    &.player-winner {
      .progress-container {
        opacity: 1;
      }
    }
    &.active-turn {
      .chip {
        opacity: 1;
        animation: borderPulses 2000ms infinite ease-out;
        @keyframes borderPulses {
          0% {
            box-shadow: inset 0px 0px 0px -5px rgba(255, 255, 255, 0.1),
              0px 0px 0px 0px rgb(255 212 170);
            // box-shadow: inset 0px 0px 0px -5px rgba(255, 255, 255, 0.1),
            //   0px 0px 0px 0px rgb(202 55 207);
          }

          100% {
            box-shadow: inset 0px 0px 0px 0px rgba(117, 117, 255, 0.03),
              0px 0px 0px 15px rgb(255 255 255 / 0%);
          }
        }
      }
      .progress-container {
        opacity: 1;
      }
    }
    .progress-container {
      opacity: 0.5;
      svg {
        color: #e1a46a;
      }
    }
    .chip {
      position: absolute;
      background: radial-gradient(circle at 30% 30%, #5f5f5f, #000);
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
      height: 25px;
      width: 25px;
      right: 20px;
      opacity: 0.75;
      border-radius: 20px;
    }
    &:first-child {
      flex-direction: row-reverse;
      .chip {
        left: 20px;
        background: radial-gradient(circle at 30% 30%, white, #868484);
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
      }
      .player-info {
        flex-direction: row;
        .copied-icon {
          left: 75px;
          position: absolute;
        }
      }
      .time {
        align-items: flex-end !important;
        .row {
          flex-direction: row-reverse;
        }
      }
    }
    .player-col {
      .player-info {
        display: flex;
        align-items: center;
        gap: 5px;
        .rating {
          font-weight: 100;
          font-size: 15px;
          font-family: sans-serif;
        }
        .address-container {
          // position: relative;
          > div {
            display: none;
          }
          .address-value {
            cursor: pointer;
            color: white;
          }
          .copied-icon {
            transition: all 0.3s ease;
            position: absolute;
            right: 75px;
          }
        }
      }
      .time {
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        .row {
          display: flex;
          gap: 5px;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .MuiButton-label {
    gap: 8px;
  }
  
  .MuiDialog-paper {
    @media (max-width: $mobile) { 
      padding: 0;
      width: 100% !important;
      max-width: 100% !important;
      margin: 0 !important;
    }
  }
.notification-round {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #41a870;
  border-radius: 50%;
  right: 30px;
  top: 20px;
  animation: borderPulse 2000ms infinite ease-out;
}