.game-field {
  // transform: skewX(0deg) scaleY(0.5); /* Initial skew and compressed state */
  // animation: boardAnimation .5s ease-out forwards;
}

@keyframes boardAnimation {
  0% {
    transform: skewX(0deg) scaleY(0.5); /* Starting point: skewed and horizontally compressed */
  }
  100% {
    transform: skewX(0deg) scaleY(1); /* Ending point: regular square */
  }
}