@import 'mixins';
@import 'variables.scss';

body {
  &.dark {
    color: $p2-dark;
    p, p .address-value {color: $p2-dark;}
    .loading::after {
        background: $p3-dark;
    }
    .empty-state {
      background-color: #212830 !important;
    }
    .theme-mode {
      background-color: #151b23;
      background-image: none;
      background: linear-gradient(180deg, rgba(35, 41, 49, 0.2) 0%, rgb(65 16 68 / 50%) 100%),
        #151b23;
      .logo {
        display: none;
      }
      .logo-dark {
        display: block;
      }
      .game-content {
        .actions {
          p {
            @include color-game-font-dark;
          }
        }
        .game-field {
          .players {
            @include color-game-font-dark;
            filter: none;
            .player {
              &.active-turn {
                .chip {
                  opacity: 1;
                  animation: borderPulsesDark 2000ms infinite ease-out;
                  @keyframes borderPulsesDark {
                    0% {
                      box-shadow: inset 0px 0px 0px -5px rgba(255, 255, 255, 0.1),
                        0px 0px 0px 0px rgb(202 55 207);
                    }
                
                    100% {
                      box-shadow: inset 0px 0px 0px 0px rgba(117, 117, 255, 0.03),
                        0px 0px 0px 15px rgb(255 255 255 / 0%);
                    }
                  }
                }
              }
              .progress-container {
                svg {
                  color: #bd3daf;
                }
              }
            }
          }
        } 
      }
      .offline-notification {
        position: fixed;
        z-index: 9999;
        background: #590a0a;
        width: 100%;
        top: 0;
        text-align: center;
      }
    }
    .main-container {
      .body {
        .tabs {
          background-color: #2a333d;
          background-image: none;
          .MuiTabs-indicator {
            background-color: $p3-dark;
          }
        }
        .leaderboard-list {
          &-header, &-row {
            border-bottom: 1px solid $p1-dark;
          }
        }
        .actions {
          p {
            @include color-game-font-dark;
          }
        }
      }
      .games-list {
        .list-header, .list-row {
          background-color: #212830 !important;
          border-bottom: 1px solid $p1-dark;
          img.link-icon {filter: brightness(16.5);}
          &:last-child { border: 0; }
          .list-row-body {
            &:hover {
              background-color: #262c36;
            }
          }
        }
        .list-cell {
          .label-draw {
            background: #35404c;
            padding: 1px 8px;
            border-radius: 100px;
          }
        }
        .col-action {
          svg {
            filter: none;
          }
        }
      }
      .leaderboard-list {
        .leaderboard-list-header {
          background-color: #212830 !important;
          border-bottom: 1px solid $p1-dark;
        }
        .leaderboard-list-row {
          background-color: #212830 !important;
          border-bottom: 1px solid $p1-dark;
          &:last-child { border: 0; }
          .leaderboard-list-row-body {
            &:hover {
                background-color: #262c36;
              }
          }
        }
      }
        
    }
    .game-field {
      background-color: #212830;
      background-image: none;
      background: linear-gradient(180deg, rgba(35, 41, 49, 0.2) 0%, rgb(87 19 67 / 25%) 100%),
        #212830;
      filter: none;
      .game-board {
        width: 100%;
        >div {
          &:before {
            border-bottom: 1.5px $p1-dark solid;
          }
          &:after {
            border-right: 1.5px $p1-dark solid;
          }
        }
      }
    }
    button {
      border-radius: 100px;
      @include button-ui-dark;
      &.btn-free {background: transparent}
      >span {
        @include game-font;
        text-transform: capitalize;
        font-size: 19px;
        @include color-game-font-dark;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }
    .dialog-modal {
      [aria-labelledby] {
        background-color: #2a333d;
        fieldset {
          legend, [aria-label] {
            color: #fff;
          }
          [aria-label] {
            label {
              >span {
                color: #fff;
              }
            }
          }
          .opponent-address, .set-bet {
            label {
              color: rgba(255, 255, 255, 0.42);
            }
            >div {
              input {
                color: #fff;
              }
              &:before {
                border-bottom: 1px solid #3d444d;
              }
              &:after {
                border-bottom: 2px solid #ff78eb
              }
            }
          }
        }
        div[id$=-dialog-title] {
          background-color: #212830;
          background-image: none;
          h2 {
            @include color-game-font-dark;
          }
        }
        @media (max-width: $mobile) {
          width: 100%;
          padding: 0;
          margin: 0;     
          max-height: 80vh; 
          max-height: 600px;
        }
      }
      &.game-options {
        padding: 8px 16px;

        h2 {
          text-align: center;
          font-size: 28px !important;
          line-height: 1.2;
        }

        .game-options-body {
          >.game-type-formrow {
            &:nth-child(1) {
              [aria-label] {
                gap: 40px;
                svg {filter: none;}
              }
            }
            &:nth-child(2) {
              [aria-label] {
                gap: 47px;
                svg {
                  filter: none;
                }
              }
              label {
                // align-items: flex-start !important;
                &:hover, &.checked {
                  border-radius: 20px !important;
                }
              }
            }
          }
          legend {
            background-color: #2a333d;
            text-shadow: 0px 3px 0px #AF139E;
            padding: 0px 5px 0px 5px;
            top: -40px;
            font-size: 24px;
            position: absolute;
            margin: 0;
          }

          .game-type-formrow {
            border: 1px solid #3d444d;
            border-radius: 15px;
            padding: 25px 15px 15px;
            width: 100%;
            


            fieldset {
              [aria-label] {
                flex-wrap: nowrap;
                gap: 5px;

                label {
                  margin: 0;
                  width: auto;
                  flex-direction: row;
                  align-items: center;
                  gap: 4px;
                  padding: 10px;
                  svg {
                      opacity: 1;
                    }
                  .info {display: none;}
                  &:hover {
                    background: linear-gradient(180deg, rgb(52 61 72) 0%, rgb(51 60 70) 100%);
                    border-radius: 100px;
                    box-shadow: none;
                  }

                  &.checked {
                    background: linear-gradient(180deg, rgb(88 74 102) 0%, rgb(93 74 87) 100%);
                    border-radius: 100px;
                    box-shadow: inset 0px 1px 3.5px rgba(253, 69, 255, 0.78);
                    svg {
                      filter: none;
                      opacity: 1;
                    }
                    .info {
                      position: absolute;
                      right: -13px;
                      top: -15px;
                      display: flex;
                      background: transparent;
                      box-shadow: none;
                      span {
                        filter: none;
                        color: #ff78eb;
                      }
                    }
                  }

                  >span {
                    @include game-font;
                    font-size: 18px;
                    padding: 0;
                    color: #fff;

                    @media (max-width: $mobile) {
                      font-size: 15px;
                    }

                    .radio-description {
                      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                      margin-top: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .table-header {
      background-color: #212830 !important;
      border-bottom: 1px solid $p1-dark;
      ~ .table-row {
        background-color: #212830 !important;
        border-bottom: 1px solid $p1-dark;
        &:hover {
          background-color: #262c36 !important;
        }
        &:last-child {
          border: 0;
        }
      }
      
    }
    .dialog-modal-finished {
      [role=dialog] {
        background-color: #2a333d;
        h2 {
          color: #fff;
        }
      }
    }
    .MuiRadio-colorSecondary.Mui-checked {
      color: #eb2cbe !important;
    }
    .how-to-play {
      .button {
        &:before {
          @include color-game-font-dark;
        }
      }
    }
    .game-content .actions > div .btn-back::before {
      @include color-game-font-dark;
    }
    .MuiIconButton-root:hover, .MuiIconButton-colorSecondary:hover {background-color: transparent;}
    .body button.info {
      background: transparent;
      color: #ff78eb;
      margin-left: 0px;

      span {
        filter: none;
      }
    }
    .NotFoundImg {
      display: none;
    }
    .NotFoundImg.dark {
      display: block;
    }
    .marquee {
      background: rgb(42 51 61 / 38%);
      &-top-rating-players { 
        &-item {
          background-color: #957dd75a;
          &-title {
            background-color: linear-gradient(174deg, rgba(255, 255, 255, 0.4) 1.04%, rgba(255, 255, 255, 0) 57%),
              linear-gradient(180deg, #441989 0%, #e28680 100%);
            background: rgb(40 34 54);
          }
          &-index {
            color: white;
            @include button-ui-dark;
          }
        }
      }
    }
    .header {
      .right-side {
        display: flex;

        // gap: 10px;
        .btn-profile {
          padding: 0 8px;
          border-radius: 100px;
          border-left: 1px solid #3d444d;
          background: #2a333d;
          border: 1px solid #3d444d;
          span {filter: none;}
          &:hover {
            transform: scale(1.025);
          }
        }
        .address-value {
          border: 4px solid $p3-dark;
          border-radius: 100px;
        }
      }
    }
    .games-sections {
      .title {
        background-color: #2a333d;
        background-image: none;
        h2 {
          background: linear-gradient(180deg, #FFFFFF 30.82%, #ceb8cc 86.99%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          filter: drop-shadow(0px 2px 0px #af129e);
        }
      }
    }
  }
  .loader-overlay ~ #marquee.marquee {display: none !important;}
  .body button.info {
    background: transparent;
    color: #e4974c;
    margin-left: 0px;

    span {
      filter: none;
    }
  }
  .theme-mode .offline-notification {
    position: fixed;
    background: #a20303;
    color: #fff;
    width: 100%;
    top: 0;
    z-index: 9999;
    text-align: center;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
    overflow: hidden;
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .bet-token {
    position: absolute;
    right: -7px;
    top: 14px;
  }
  .game-card {
    position: relative;

    .col-action {
      svg {
        filter: hue-rotate(105deg);
      }
    }
  }
  #webpack-dev-server-client-overlay {display: none !important;}
}

.app-container {
  @media (max-width: $mobile) {
    gap: 30px;
    .main-container {
      .body {
        width: 94vw;

        .total-rooms-label {
          font-size: 15px;
        }
      }
    }
    .board-container {
      max-width: 100%;
      width: 100% !important;
      overflow: scroll;
    }
    .game-content {
      width: 700px;
    }
  }
}
//up