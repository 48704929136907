@import '../../App/variables';

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1301;
}

.loader {
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 999;
  width: 350px;
  height: 180px;
  border-radius: 10px;

  label {
    color: #fff;
    font-size: 20px;
    animation: bit 0.6s alternate infinite;
    font-family: "Lilita One", sans-serif;
  }
}

.loading {
  width: 100%;
  height: 10px;
  background: lightgrey;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 10px;
  background: $p1;
  border-radius: 10px;
  z-index: 1;
  animation: loading 0.6s alternate infinite;
}



@keyframes bit {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    left: -25%;
  }
  100% {
    left: 70%;
  }
}
