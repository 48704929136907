.game-content {
  display: flex;
  flex-direction: column;

  .game {
    &-player-container {
      padding: 20px;
      display: flex;
      flex-direction: row;
      gap: 20px;

      &-details {
        display: inline-flex;
        gap: 20px;
        &-address {
          font-size: 15px;
        }
      }
    }
    &-footer {
      margin-top: 10px;
    }
  }
  circle.MuiCircularProgress-circle.MuiCircularProgress-circleStatic {
    color: #18cf2e;
  }
}

.game-page {
  .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      font-size: 16px;
    }
  }
}