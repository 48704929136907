@import '../../App/mixins';

.game-card {
  cursor: pointer;
  position: relative;
  .list-cell {
    .label-draw {
      background: #f5c69b;
      padding: 1px 8px;
      border-radius: 6px;
    }
    
  }
  .col-action {
    svg {
      // @include bg-ui-gradient;
      bottom: -4px;
      position: relative;
      right: -4px
    }
  }
  .cross-icon {
    width: 10px;
  }
  .game-size-container {
    display: flex;
    align-items: center;
  }
}

.game-card-preview {
  background-color: #c98644;
}
.MuiTooltip-tooltip {
  background: radial-gradient(black, transparent);
  background-color: transparent;
}
.MuiTooltip-tooltip {

}
.MuiTooltip-tooltipPlacementRight {
  background-color: transparent !important;
}
