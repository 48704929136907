@import '../../App/mixins';

.not_found {
  text-align: center;
  margin-top: 100px;
  display: flex;
  gap: 22px;
  flex-direction: column;
  align-items: center;
  @include game-font;
  .NotFoundImg.dark {
    display: none;
  }
  h1 {
    @include game-font;
    font-size: 35px;
    line-height: 1.2;
    text-align: center
  }
  > span {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    opacity: .65;
  }
}