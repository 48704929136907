
.address-container {
  .address-value {
    text-decoration: none;
    color: inherit;
  }
  .copy-btn {
    margin-left: 5px;
    cursor: pointer;
    color: #fff;
    height: 16px;
  }
  .copy-btn:hover {
    opacity: 0.8;
  }
}