@import '../../App/mixins.scss';
.marquee {
  position: fixed;
  z-index: 1;
  // background: rgb(42 51 61 / 38%);
  background: rgb(255 255 255 / 30%);
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  width: calc(100% - 130px) !important;
  cursor: pointer;
  bottom: 15px;
  border-radius: 100px;
  padding: 8px;
  height: 35px;
  &-text-line {
    display: flex;
    flex-direction: row;
  }
  &-top-rating-players {
    display: flex;
    flex-direction: row;
    gap: 10px;

    &-item {
      display: flex;
      align-items: center;
      gap: 5px;
      background-color: rgb(255 255 255 / 35%);
      padding: 0 5px 0 0;
      border-radius: 20px;

      &-title {
        background-color: linear-gradient(174deg, rgba(255, 255, 255, 0.4) 1.04%, rgba(255, 255, 255, 0) 57%),
        linear-gradient(180deg, #441989 0%, #e28680 100%);
        position: sticky;
        left: -10px;
        // background: rgb(40 34 54);
        background: rgb(230 203 171);
        padding: 0px 4px 0px 10px;
        bottom: 15px;
        height: 35px;
        margin-left: -9px;
        border-radius: 100px 0 0 100px;
        @include game-font;
        font-size: 18px;
        display: flex;
        align-items: center;
        .win-icon {
          height: 20px;
          width: 20px;
          margin-right: 5px;
        }
      }

      &-index {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        @include button-ui;
        border-radius: 100px;
        filter: none;
        padding: 2px;
        width: 20px;
        height: 20px;
      }
    }
  }
}