@import '../../App/mixins';

.nickname {
  .game-options-body .game-type-formrow {
      border: 0 !important;
      margin: 0;
    }
  button {
    width: 180px;
  }
}