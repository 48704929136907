@import '../../App/variables';

@mixin col-1 {width: 50px;}
@mixin col-2 {flex: 2;}
@mixin col-3 {flex: 0;}

.leaderboard-list {
  display: grid;
  width: 100%;
  flex-direction: column;
  width: 100%;
  // height: calc(100vh - 250px);
  overflow: scroll;
  border-radius: 0 0 18px 18px;

  &-header {
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);
    // background-image: url('../../assets/imgs/wood-bg.jpg');
    position: sticky;
    top: 0;
    z-index: 1;
    justify-content: space-between;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid $p1;
    backdrop-filter: blur(2px);
    &:nth-child(1){
      @include col-1;
    }
    :nth-child(2) {
      @include col-2;
    }
    :nth-child(3) {
      @include col-3;
    }
  }

  &-row {
    background-color: rgba(255, 255, 255, 0.5) !important;
    box-shadow: none;
    border-radius: 0 !important;
    margin: 0 !important;
    border-bottom: 1px solid $p1;
    border-radius: 0;
    padding: 0px !important;
    transition: all .3s;
    box-shadow: none !important;
    &:last-child {border: 0;}
    img {
      width: 20px;
      &.link-icon {width: 11px}
    }
    .col-id {
      @include col-1;
    }
    .col-player {
      @include col-2;
      p {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    .col-bets {
      @include col-3;
      align-items: center;
      display: flex;
      gap: 5px;
    }

    &-body {
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: space-between;
      padding: 16px !important;
      
    }
  }
  .wld-container {
    color: inherit !important;
    gap: 2px;
    display: flex;
    &-win {
      color: #3a953a;
    }
    &-lose {
      color: #f16d6d;
    }
  }
}

.leaderboard-table {
  width: 100%;
}

.pagination-block {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end
}