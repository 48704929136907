@import '../../App/mixins';

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
  border-radius: 0 0 18px 18px;

  &-row {
    display: flex;
    width: 100%;
    padding: 16px;
    gap: 10px;
    background-color: rgba(255, 255, 255, 0.5) !important;
    box-shadow: none;
    margin: 0 !important;
    border-bottom: 1px solid $p1;
    border-radius: 0;
    transition: all .3s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.65) !important;
    }
  
    &:last-child {
      border: 0;
    }

    &-cell {
      white-space: nowrap;
      text-align: left;
      flex-grow: 1;
      flex-shrink: 0;
      flex: 1;
    }
  }
  
  &-header {
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);
    position: sticky;
    @include game-font;
    top: 0;
    z-index: 1;
    justify-content: space-between;
    height: 45px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid $p1;
    backdrop-filter: blur(2px);

    &-cell {
      white-space: nowrap;
      text-align: left;
      flex-grow: 1;
      flex-shrink: 0;
      flex: 1;
    }
  }
  
  &-cell {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    gap: 4px;
    .wld-container {
      color: inherit !important;
      gap: 2px;
      display: flex;
  
      &-win {
        color: #3a953a;
      }
  
      &-lose {
        color: #f16d6d;
      }
    }
    > span {
      &:nth-child(1) {
        color: #3a953a
      }
      &:nth-child(2) {
        color: #f16d6d
      }
    }
    button.info {
      background: transparent;
      color: #e4974c;
      margin-left: -2px;
      span {
        filter: none;
      }
    }
  }
}



