@import '../../../../App/mixins';

.dark .dialog-modal-finished {
  [role="dialog"] {
    h2 {
      &::before {
        background-image: url('../../../../assets/imgs/finish-modal-status-dark.png');
      }
    }
  }
}

.dialog-modal-finished {
  .actions {
    display: flex;
    justify-content: space-around;
    padding: 8px 24px 24px;
    button.claim-btn {
        animation: borderPulse 2000ms infinite ease-out;
      }
      @keyframes borderPulse {
        0% {
          box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255, 0.4), 0px 0px 0px 0px rgba(255, 255, 255, 1);
        }
        100% {
          box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255, 0.2), 0px 0px 0px 10px rgba(255, 255, 255, 0);
        }
      }
  }
  &[itemtype="loose"] {
    [role="dialog"] {
      h2 {
        &::before {
          content: "";
          background-position-y: -133px
        }
      }
    }
  }
  &[itemtype="win"] {
    [role="dialog"] {
      h2 {
        &::before {
          content: "";
          background-position-y: 0;
        }
      }
    }
  }
  &[itemtype="draw"] {
    [role="dialog"] {
      h2 {
        &::before {
          content: "";
          background-position-y: -268px;
        }
      }
    }
  }
  &[itemtype="quit"] {
    [role="dialog"] {
      h2 {
        &::before {
          content: "";
          background-position-y: -401px;
        }
      }
    }
  }
  [role="dialog"] {
    border-radius: 18px;
    width: 300px;
    overflow: visible;
    h2 {
      @include game-font;
      font-size: 22px;
      color: #c78139;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 50px;
      &::before {
        background-image: url('../../../../assets/imgs/finish-modal-status.png');
        width: 390px;
        height: 133px;
        position: absolute;
        top: -60px;
        background-size: cover;
        margin-bottom: 8px;
        font-size: 32px;
      }
    } 
  }
}