@import '../../App/mixins';

.list-header > div {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  gap: 4px;
  @include game-font;
}

.room,
.bet,
.time,
.winner:hover {
  cursor: pointer;
}

.sort-icon {
  opacity: 0.5;
  min-width: 20px !important;
}

.games-table {
  width: 100%;
}

.pagination-block {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  li button, li > div {
    @include game-font;
    font-size: 19px;
    color: rgb(255 255 255 / 40%);
  }
}
