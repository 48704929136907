@import '../../App/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    cursor: pointer;
    &.desktop-logo {
      display: block !important;
    }

    &.mobile-logo {
      display: none !important;
    }
  }

  .right-side {
    display: flex;
    align-items: center;
    gap: 15px;

    .address-value {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: $mobile) {
    height: unset;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 0px;

    .right-side {
      flex-direction: row;
      gap: 10px;
    }
  }

  @media (max-width: 480px) {
    .logo.desktop-logo {
      display: none !important;
    }

    .logo.mobile-logo {
      display: block !important;
      height: 40px;
    }

    .right-side {
      flex-direction: row;
      gap: 10px;
    }
  }
}